import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { FaLongArrowAltLeft } from 'react-icons/fa';

import { Wave1 } from '../components/wave';
import WaveImg from '../assets/images/wave.png';
import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import CTAComponent from '../components/cta';
import GoldenButton from '../components/buttons/GoldenButton';

const Background = styled.div`
    background-color: transparent;
    background: url(${WaveImg}) no-repeat contain;
    background-position: top 317px;
    margin-bottom: 112px;
    padding-top: 133px;
    @media screen and (max-width: 767px) {
        margin-bottom: 129px;
    }
`;
const BackButton = styled(Link)`
    display: flex;
    font-family: 'Renogare-Regular';
    color: white;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none;
    margin-left: 64px;
    letter-spacing: 0.5px;
    z-index: 2;
    position: absolute;
    width: fit-content;
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        /* padding-top: 109px; */
    }
`;
const Heading = styled.div`
    margin-left: 64px;
    margin-right: 64px;
    padding-top: 60px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
        margin-left: 32px;
        margin-right: 32px;
    }
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        padding-top: 30px;
    }
`;
const HeadingButton = styled.div`
    margin-left: 64px;
    margin-right: 64px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
        margin-left: 32px;
        margin-right: 32px;
    }
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        padding-top: 10px;
    }
`;
const Title = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 48px;
    line-height: 60px;
    text-align: left;
    letter-spacing: -4px;
    color: white;
    user-select: none;
    margin-left: 176px;
    margin-right: 176px;
    @media screen and (max-width: 767px) {
        font-size: 27px;
        line-height: 42px;
        margin-left: 0;
        margin-right: 0;
        letter-spacing: -2.29px;
    }
`;

const TitleButton = styled.div`
    margin-left: 176px;
    margin-right: 176px;
    @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-right: 0;
    }
`;
const Image = styled.div`
    margin-left: 9%;
    margin-right: 9%;
    padding-top: 60px;
    border-radius: 4px;
    text-align: center;
    img {
        width: 85%;
        max-height: calc(80vh);
        border-radius: 4px;
        object-fit: cover;
    }
    @media screen and (max-width: 1024px) {
        margin-left: 5%;
        margin-right: 5%;
        img {
            border-radius: 2px;
            max-height: calc(65vh);
        }
    }
    @media screen and (max-width: 767px) {
        margin-left: 0px;
        margin-right: 0px;
        img {
            border-radius: 0px;
            object-fit: cover;
            max-height: calc(55vh);
        }
    }
`;
const Content = styled.div`
    margin-left: 18%;
    margin-right: 18%;
    font-family: 'Neuzeit Grotesk';
    font-size: 23px;
    line-height: 32px;
    color: white;
    a {
        color: #cca352;
    }
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: normal;
        color: rgba(255, 255, 255, 0.9);
    }
    h2 {
        font-size: 36px;
        line-height: 40px;
        letter-spacing: -1.5px;
        color: white;
        font-family: 'Renogare-Regular';
        @media screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 28px;
            letter-spacing: normal;
        }
    }
    ul {
        list-style: none;
        li::before {
            content: '•';
            color: #a68353;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }

        @media screen and (max-width: 767px) {
            padding-left: 12px;
        }
    }
`;
const WaveSection = styled.div`
    position: absolute;
    width: 100%;
    /* z-index: -1; */
    margin-top: -170px;
    @media screen and (max-width: 767px) {
        margin-top: -232px;
    }
`;
const CaseStudyContent = styled.div`
    margin-left: 176px;
    margin-right: 176px;
    margin-top: 60px;

    @media screen and (max-width: 1024px) {
        margin-left: 64px;
        margin-right: 64px;
        margin-top: 32px;
    }
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 32px;
    }
`;

const ExternalLink = styled.a`
    color: white;
    text-decoration: none;
    display: inline-block;
    margin-top: 1em;
`;

const getCaseStudyContent = (data, url_slug) =>
    data.case_study.case_studies.find(post => post.url_slug === url_slug);

export default function Template({ data, pageContext }) {
    const {
        title,
        featured_image,
        body: content,
        pdf,
        seo_description,
        sharing_image,
    } = getCaseStudyContent(data, pageContext.url_slug);
    return (
        <Layout menu="INSIGHTS">
            <SEOComponent
                title={title}
                image={sharing_image}
                description={seo_description}
            />
            <Background>
                <BackButton to="/case-studies">
                    <FaLongArrowAltLeft color="white" size={20} />{' '}
                    <span style={{ marginLeft: 4 }}>BACK TO CASE STUDIES</span>
                </BackButton>
                <Heading>
                    <Title>{title}</Title>
                </Heading>
                <WaveSection>
                    <Wave1 />
                </WaveSection>
                <HeadingButton>
                    <TitleButton>
                        <ExternalLink
                            href={pdf}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <GoldenButton>See Full Case Study</GoldenButton>
                        </ExternalLink>
                    </TitleButton>
                </HeadingButton>
                <Image>
                    <img
                        alt={title}
                        src={featured_image} // use normal <img> attributes as props
                        loading="lazy"
                    />
                </Image>
                <Content>
                    <div dangerouslySetInnerHTML={{__html: content}} />    
                    <ExternalLink
                        href={pdf}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <GoldenButton>See Full Case Study</GoldenButton>
                    </ExternalLink>
                </Content>
                <CaseStudyContent></CaseStudyContent>
            </Background>
            <CTAComponent
                heading={`Reach real people, make real connections,\n
and drive real brand conversations. `}
            />
        </Layout>
    );
}

export const pageQuery = graphql`
    {
        case_study: butterPage(slug: { eq: "case-studies" }) {
            case_studies {
                title
                featured_image
                url_slug
                body
                pdf
                seo_description
                sharing_image
            }
        }
    }
`;
